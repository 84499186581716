import { Pipe, PipeTransform } from '@angular/core';
import { ProductionLocation } from './production-location.enum';

@Pipe({
  name: 'productionLocation'
})
export class ProductionLocationPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (!value || typeof value !== 'string') {
      return 'Unknown Location';
    }

    const sanitizedValue = value.trim().toUpperCase();
    return ProductionLocation[sanitizedValue as keyof typeof ProductionLocation] || 'Unknown Location';
  }
}
