import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PrinterLocation } from '../../checklist/order-item-overview/printing-dialog/PrinterLocation';

@Component({
  selector: 'app-printer-selection-dialog',
  templateUrl: './printer-selection-dialog.component.html',
  styleUrls: ['./printer-selection-dialog.component.scss']
})
export class PrinterSelectionDialogComponent {
  printerLocations = Object.entries(PrinterLocation);
  selectedPrinterLocationKey: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<PrinterSelectionDialogComponent>
  ) {}

  confirmSelection() {
    this.dialogRef.close(this.selectedPrinterLocationKey);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
