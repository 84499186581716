import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {asyncScheduler, BehaviorSubject, forkJoin, Observable, Subject, Subscription} from 'rxjs';
import {buffer, debounceTime, distinctUntilChanged, filter, finalize, map, takeUntil, throttleTime} from 'rxjs/operators';
import {PotService} from '../pot.service';
import {PotStock} from '../pot';
import {WithScrollContainer} from '../../../_helpers/with-scroll-container';
import {AddNewStockDialogComponent} from '../add-new-stock-dialog/add-new-stock-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BarcodeScannerService} from '../../../barcode-scanner/barcode-scanner.service';
import {
  ScanBarcodeWithCameraDialog
} from '../../../barcode-scanner/scan-barcode-with-camera-dialog/scan-barcode-with-camera-dialog.component';
import {Utils} from '../../../_helpers/Utils';
import {SortingService} from '../../../_services/sorting.service';

class BedWithVoorraad {
  naam: string;
  voorraad: PotStock[];
  loading = false;

  constructor(naam: string, voorraad?: PotStock[]) {
    this.naam = naam;
    this.voorraad = voorraad;
  }
}

@Component({
  selector: 'app-pot-count',
  templateUrl: './pot-count.component.html',
  styleUrls: ['./pot-count.component.scss']
})
export class PotCountComponent extends WithScrollContainer() implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  @ViewChild('bedInput', {static: true}) bedInput: ElementRef;

  searchTerm: string;
  searchTermSubject = new BehaviorSubject<string>('');
  allBeds: BedWithVoorraad[] = [];
  visibleBeds: BedWithVoorraad[] = [];
  displayedColumns = ['naam', 'total', 'vrij', 'vr', 'potmaat'];
  fetchingBedsSubject = new BehaviorSubject<boolean>(false);
  fetchingBeds$: Observable<boolean> = this.fetchingBedsSubject.asObservable();
  firstVisibleBedIndex: number;
  noBedsFoundMessage: string;
  subscriptions = new Subscription();
  barcodeInputSubject = new Subject<number>();
  barcodeInput$ = this.barcodeInputSubject.asObservable();
  useCustomSort = false;
  selectedPosition: string;
  private previousDates: Map<number, Date> = new Map<number, Date>();


  private readonly AMOUNT_BEDS_VISIBLE = 10;
  protected readonly Utils = Utils;

  constructor(
    private potService: PotService,
    private route: ActivatedRoute,
    private _dialog: MatDialog,
    private barcodeScannerService: BarcodeScannerService,
    private sortingService: SortingService
  ) {
    super();
  }

  ngOnInit() {
    this.setupSearchFieldBehaviour();
    this.fetchAllBeds().then(() => {
      this.searchTerm = this.route.snapshot.paramMap.get('bednr');
      this.searchTermSubject.next(this.searchTerm);
    });
    this.setupBarcodeScanning();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async fetchAllBeds() {
    this.fetchingBedsSubject.next(true);
    try {
      const beds = await this.potService.getBedNrs().toPromise();
      this.allBeds = beds
        .filter(val => val !== null)
        .sort((a, b) => {
          return this.useCustomSort
            ? this.sortingService.customLocationCompare(a, b)
            : a.localeCompare(b);
        })
        .map(bed => new BedWithVoorraad(bed));
    } catch (error) {
      console.error('Unable to get all beds: ' + error);
    }
    this.fetchingBedsSubject.next(false);
  }

  syncVisibleBedsWithAllBeds() {
    this.visibleBeds = this.allBeds.slice(this.firstVisibleBedIndex, this.firstVisibleBedIndex + this.AMOUNT_BEDS_VISIBLE);
  }

  async fetchVoorraad(bed: BedWithVoorraad) {
    bed.loading = true;
    try {
      const retrievedStock = await this.potService.getPotStockByBedNr(bed.naam).toPromise();
      bed.voorraad = retrievedStock.sort((a, b) => a.plantName.localeCompare(b.plantName));
      bed.loading = false;
    } catch (error) {
      console.error('Unable to get voorraad for bednr ' + bed.naam + '\n' + error);
    }
  }

  loadMoreItemsBottom() {
    const bedsToAdd: BedWithVoorraad[] = this.allBeds.slice(
      this.firstVisibleBedIndex + this.visibleBeds.length,
      this.firstVisibleBedIndex + this.visibleBeds.length + 5
    );
    bedsToAdd.forEach(bed => this.visibleBeds.push(bed));
    const amountOfBedsToHide = Math.max(this.visibleBeds.length - this.AMOUNT_BEDS_VISIBLE, 0);
    this.visibleBeds.splice(0, amountOfBedsToHide);
    this.firstVisibleBedIndex += amountOfBedsToHide;
    bedsToAdd.forEach(bed => this.fetchVoorraad(bed));
  }

  loadMoreItemsTop() {
    const bedsToAdd: BedWithVoorraad[] = this.allBeds.slice(
      Math.max(0, this.firstVisibleBedIndex - 6),
      Math.max(5, this.firstVisibleBedIndex - 1)
    );
    bedsToAdd.reverse().forEach(bed => this.visibleBeds.unshift(bed));
    const amountOfBedsToHide = Math.max(this.visibleBeds.length - this.AMOUNT_BEDS_VISIBLE, 0);
    this.visibleBeds.splice(-amountOfBedsToHide, amountOfBedsToHide);
    this.firstVisibleBedIndex -= bedsToAdd.length;
    bedsToAdd.forEach(bed => this.fetchVoorraad(bed));
  }

  public async onAttach() {
    this.fetchAllBeds().then(() => {
      this.syncVisibleBedsWithAllBeds();
      this.fetchingBedsSubject.next(true);
      forkJoin(this.visibleBeds.map(bed => this.fetchVoorraad(bed)))
        .pipe(finalize(() => this.fetchingBedsSubject.next(false)))
        .subscribe(() => {
          if (this.scrollPosition) {
            this.scrollContainer.nativeElement.scrollTo(0, this.scrollPosition);
          } else if (!this.searchTerm) {
            this.bedInput.nativeElement.focus();
          }
        });
    });
  }

  private setupSearchFieldBehaviour() {
    this.searchTermSubject.pipe(
      takeUntil(this.onDestroy$),
      debounceTime(1000),
      filter(str => str && str.length >= 2),
      distinctUntilChanged(),
    ).subscribe(searchTerm => this.applyFilter(searchTerm));
  }

  private applyFilter(term: string) {
    this.visibleBeds = [];

    if (term && term.length >= 2) {
      term = term.trim().toLowerCase();
      const filteredBeds = this.allBeds.filter(bed => {
        const matchesTerm = bed.naam?.toLowerCase().startsWith(term);

        if (this.selectedPosition) {
          const endsWithSelected = bed.naam?.toLowerCase().trim().endsWith(this.selectedPosition.toLowerCase());
          return matchesTerm && endsWithSelected;
        }

        return matchesTerm;
      });

      if (filteredBeds.length > 0) {
        this.noBedsFoundMessage = undefined;
        this.firstVisibleBedIndex = this.allBeds.indexOf(filteredBeds[0]);
        this.visibleBeds = filteredBeds;
        this.visibleBeds.forEach(bed => this.fetchVoorraad(bed));
      } else {
        this.noBedsFoundMessage = 'Geen bedden gevonden voor zoekterm: ' + term;
      }
    } else {
      this.noBedsFoundMessage = undefined;
      this.visibleBeds = [];
    }
  }


  openAddStockDialog() {
    this._dialog.open(AddNewStockDialogComponent, {
      width: '600px',
      height: '600px',
      maxWidth: '90%',
      maxHeight: '90%',
    })
      .afterClosed()
      .subscribe(() => this.onAttach());
  }

  async setLastInspection(event: MouseEvent, stock: PotStock, bed: BedWithVoorraad) {
    event.stopPropagation();
    await this.updateStockInspection(stock);
    await this.fetchVoorraad(bed);
  }

  async setLastInspectionForLocation(event: MouseEvent, bed: BedWithVoorraad) {
    event.stopPropagation();
    const updatePromises = bed.voorraad.map((stock: PotStock) => this.updateStockInspection(stock));
    await Promise.all(updatePromises);
    await this.fetchVoorraad(bed);
  }

  private async updateStockInspection(stock: PotStock) {
    if (this.previousDates.has(stock.id) &&
      (new Date(this.previousDates.get(stock.id)) < new Date() || this.previousDates.get(stock.id) === undefined)) {
      stock.lastInspection = this.previousDates.get(stock.id);
      this.previousDates.delete(stock.id);
    } else {
      this.previousDates.set(stock.id, stock.lastInspection);
      stock.lastInspection = new Date();
    }
    await this.potService.updateStock(stock).toPromise();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.defaultPrevented || (event.target instanceof HTMLInputElement)) {
      return;
    }
    const number = Number(event.key);
    if (number >= 0) {
      this.barcodeInputSubject.next(number);
      event.preventDefault();
    }
  }

  private setupBarcodeScanning() {
    this.subscriptions.add(this.barcodeInput$.pipe(
      buffer(this.barcodeInput$.pipe(throttleTime(100, asyncScheduler, {leading: false, trailing: true}))),
      filter(barcodeArray => barcodeArray.length > 3),
      map(barcodeArray => +barcodeArray.join(''))
    ).subscribe({
      next: (barcode: number) => {
        this.barcodeScannerService.nextBarcode(barcode);
      }
    }));
  }

  openCameraScanner() {
    const dialog: MatDialogRef<ScanBarcodeWithCameraDialog> = this._dialog.open(ScanBarcodeWithCameraDialog, {});
    dialog.afterClosed().subscribe((barcode: number) => {
      if (barcode) {
        this.barcodeScannerService.nextBarcode(barcode);
      }
    });
  }

  onSortToggleChange(checked: boolean) {
    this.useCustomSort = checked;
    this.applySorting();
  }

  private applySorting() {
    if (!this.allBeds) {
      return;
    }

    this.allBeds.sort((a, b) => {
      return this.useCustomSort
        ? this.sortingService.customLocationCompare(a.naam, b.naam)
        : a.naam.localeCompare(b.naam);
    });

    this.syncVisibleBedsWithAllBeds();
    this.reapplyFilter();
  }

  protected reapplyFilter() {
    if (this.searchTermSubject.value) {
      this.applyFilter(this.searchTermSubject.value);
    }
  }
}
