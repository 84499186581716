import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {PotCountComponent} from '../voorraad/pot/pot-count/pot-count.component';
import {PotSearchComponent} from '../voorraad/pot/pot-search/pot-search.component';
import {PotDetailComponent} from '../voorraad/pot/pot-detail/pot-detail.component';
import {PortaalComponent} from '../portaal/portaal.component';
import {LoginComponent} from '../login/login.component';
import {AuthGuard} from '../_guards/auth.guard';
import {PlugStockSearchComponent} from '../voorraad/stek/stek-search/plug-stock-search.component';
import {PlugStockDetailComponent} from '../voorraad/stek/stek-detail/plug-stock-detail.component';
import {OpenGroundStockSearchComponent} from '../voorraad/vollegrond/vollegrond-search/open-ground-stock-search.component';
import {OpenGroundStockDetailComponent} from '../voorraad/vollegrond/vollegrond-detail/open-ground-stock-detail.component';
import {CustomerOverviewComponent} from '../checklist/customer-overview/customer-overview.component';
import {OrderDetailComponent} from '../checklist/order-detail/order-detail.component';
import {SalesOrderItemOverviewComponent} from '../checklist/order-item-overview/sales-order-item-overview.component';
import {RegisterTotalsComponent} from '../checklist/register-totals/register-totals.component';
import {CheckListOrderResolver} from '../checklist/check-list-order-resolver.service';
import {ScanPrintListComponent} from '../checklist/scan-print-list/scan-print-list.component';
import {PotCountPriorityComponent} from '../voorraad/pot/pot-count-priority/pot-count-priority.component';
import {PotJobsComponent} from '../voorraad/pot/pot-jobs/pot-jobs.component';
import {PotStockCountAndPlaceComponent} from '../voorraad/pot/pot-stock-count-and-place/pot-stock-count-and-place.component';
import {StekCountComponent} from '../voorraad/stek/stek-count/stek-count.component';
import {PlantingJobsComponent} from '../voorraad/vollegrond/planting-jobs/planting-jobs.component';
import {PotHistoryComponent} from '../voorraad/pot/pot-history/pot-history.component';
import {PotControlledCountComponent} from '../voorraad/pot/pot-controlled-count/pot-controlled-count.component';
import {BarcodeScannerComponent} from '../barcode-scanner/barcode-scanner.component';
import {ProductionJobResolverService} from '../voorraad/pot/pot-jobs/create-stock-dialog/production-job-resolver.service';
import {DashboardComponent} from '../dashboard/dashboard.component';

const routes: Routes = [
  {path: '', component: PortaalComponent, pathMatch: 'full', data: {title: 'Portaal'}, canActivate: [AuthGuard]},
  {path: 'login', data: {title: 'Portaal login'}, component: LoginComponent},

  {path: 'voorraad/stek', component: PlugStockSearchComponent, data: {title: 'Stek'}, canActivate: [AuthGuard]},
  {path: 'voorraad/stek/tellen/:location', component: StekCountComponent, data: {title: 'Stekvoorraad per bed'}, canActivate: [AuthGuard]},
  {path: 'voorraad/stek/tellen', component: StekCountComponent, data: {title: 'Stekvoorraad per bed'}, canActivate: [AuthGuard]},
  {path: 'voorraad/stek/:id', component: PlugStockDetailComponent, data: {title: 'Stek'}, canActivate: [AuthGuard]},
  {path: 'voorraad/stek/zoeken/:searchTerm', component: PlugStockSearchComponent, data: {title: 'Stek'}, canActivate: [AuthGuard]},
  {
    path: 'voorraad/vollegrond/uitplant-opdrachten',
    component: PlantingJobsComponent,
    data: {title: 'Uitplant opdrachten'},
    resolve: {productionJob: ProductionJobResolverService},
    canActivate: [AuthGuard]
  },
  {path: 'voorraad/vollegrond', component: OpenGroundStockSearchComponent, data: {title: 'Vollegrond'}, canActivate: [AuthGuard]},
  {path: 'voorraad/vollegrond/:id', component: OpenGroundStockDetailComponent, data: {title: 'Vollegrond'}, canActivate: [AuthGuard]},
  {
    path: 'voorraad/vollegrond/zoeken/:searchTerm',
    component: OpenGroundStockSearchComponent,
    data: {title: 'Vollegrond'},
    canActivate: [AuthGuard]
  },
  {path: 'voorraad/potten/tellen/:location', component: PotCountComponent, data: {title: 'Potvoorraad per bed'}, canActivate: [AuthGuard]},
  {path: 'voorraad/potten/tellen', component: PotCountComponent, data: {title: 'Potvoorraad per bed'}, canActivate: [AuthGuard]},
  {
    path: 'voorraad/potten/prioriteiten-tellen',
    component: PotCountPriorityComponent,
    data: {title: 'Prioriteiten telling'},
    canActivate: [AuthGuard]
  },
  {
    path: 'voorraad/potten/gestuurde-telling',
    component: PotControlledCountComponent,
    data: {title: 'Gestuurde telling'},
    canActivate: [AuthGuard]
  },
  {
    path: 'voorraad/potten/oppot-opdrachten',
    component: PotJobsComponent,
    data: {title: 'Oppot opdrachten'},
    resolve: {productionJob: ProductionJobResolverService},
    canActivate: [AuthGuard]
  },
  {
    path: 'voorraad/potten/oppot-wegzetten',
    component: PotStockCountAndPlaceComponent,
    data: {title: 'Oppot wegzetten'},
    canActivate: [AuthGuard]
  },
  {path: 'voorraad/potten/zoeken/:searchTerm', component: PotSearchComponent, data: {title: 'Potvoorraad'}, canActivate: [AuthGuard]},
  {path: 'voorraad/potten/:id', component: PotDetailComponent, data: {title: 'Potvoorraad-partij'}, canActivate: [AuthGuard]},
  {path: 'voorraad/potten', component: PotSearchComponent, data: {title: 'Potvoorraad'}, canActivate: [AuthGuard]},
  {path: 'voorraad/potten-history', component: PotHistoryComponent, data: {title: 'Historische potvoorraad'}, canActivate: [AuthGuard]},
  {
    path: 'afchecklijst',
    component: CustomerOverviewComponent,
    data: {title: 'Afchecklijst - Bonnen'},
    canActivate: [AuthGuard]
  },
  {
    path: 'afchecklijst/regels',
    component: SalesOrderItemOverviewComponent,
    data: {title: 'Afchecklijst - Regels'},
    canActivate: [AuthGuard]
  },
  {path: 'afchecklijst/totalen', component: RegisterTotalsComponent, data: {title: 'Afchecklijst - Totalen'}, canActivate: [AuthGuard]},
  {
    path: 'afchecklijst/:orderId',
    component: OrderDetailComponent,
    data: {title: 'Afchecklijst'},
    resolve: {checklistOrder: CheckListOrderResolver},
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {title: 'Dashboard'},
    canActivate: [AuthGuard]
  },
  {
    path: 'print/promesse',
    component: ScanPrintListComponent,
    data: {title: 'Print Promesse stekers'},
    canActivate: [AuthGuard]
  },
  {
    path: 'scanner',
    component: BarcodeScannerComponent,
    data: {title: 'Barcode scanner'},
    canActivate: [AuthGuard]
  },


  // otherwise redirect to home
  {path: '**', redirectTo: ''}
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  providers: [
    CheckListOrderResolver
  ]
})
export class AppRoutingModule {
}
