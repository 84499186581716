<h2 mat-dialog-title>Selecteer een printer</h2>

<mat-dialog-content>
  <mat-button-toggle-group [(ngModel)]="selectedPrinterLocationKey" appearance="legacy" aria-label="Selecteer een printerlocatie" class="printer-toggle-group">
    <mat-button-toggle *ngFor="let printerType of printerLocations" [value]="printerType[0]" class="printer-toggle">
      {{ printerType[1] }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-flat-button color="warn" (click)="cancel()">Annuleren</button>
  <button mat-flat-button color="accent" (click)="confirmSelection()" [disabled]="!selectedPrinterLocationKey">
    Bevestigen
  </button>
</mat-dialog-actions>
