import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {WithScrollContainer} from '../../../_helpers/with-scroll-container';
import {AddNewPlugStockDialogComponent} from '../add-new-stock-dialog/add-new-plug-stock-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {PlugStockService} from '../plug-stock.service';
import {PlugStock} from '../plugStock';
import {SortingService} from '../../../_services/sorting.service';
import {Utils} from '../../../_helpers/Utils';

class BedWithVoorraad {
  naam: string;
  voorraad: PlugStock[];
  loading = false;

  constructor(naam: string, voorraad?: PlugStock[]) {
    this.naam = naam;
    this.voorraad = voorraad;
  }
}

@Component({
  selector: 'app-pot-count',
  templateUrl: './stek-count.component.html',
  styleUrls: ['./stek-count.component.scss']
})
export class StekCountComponent extends WithScrollContainer() implements OnInit, AfterViewInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  @ViewChild('bedInput', {static: true}) bedInput: ElementRef;

  searchTerm: string;
  searchTermSubject = new BehaviorSubject<string>('');
  allBeds: BedWithVoorraad[] = [];
  visibleBeds: BedWithVoorraad[] = [];
  displayedColumns = ['naam', 'total', 'vrij', 'vr', 'potmaat'];
  fetchingBedsSubject = new BehaviorSubject<boolean>(false);
  fetchingBeds$: Observable<boolean> = this.fetchingBedsSubject.asObservable();
  firstVisibleBedIndex: number;
  noBedsFoundMessage: string;
  useCustomSort = false;
  private previousDates: Map<number, Date> = new Map<number, Date>();
  protected selectedPosition: string;

  private readonly AMOUNT_BEDS_VISIBLE = 10;
  protected readonly Utils = Utils;

  constructor(
    private plugService: PlugStockService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private sortingService: SortingService
  ) {
    super();
  }

  ngOnInit() {
    this.setupSearchFieldBehaviour();
    this.fetchAllBeds().then(() => {
      this.searchTerm = this.route.snapshot.paramMap.get('bednr');
      this.searchTermSubject.next(this.searchTerm);
    });
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async fetchAllBeds() {
    this.fetchingBedsSubject.next(true);
    try {
      const beds = await this.plugService.getBedNrs().toPromise();
      this.allBeds = beds
        .filter(val => val !== null)
        .sort((a, b) => {
          return this.sortingService.customLocationCompare(a, b);
        })
        .map(bed => new BedWithVoorraad(bed));
    } catch (error) {
      console.error('Unable to get all beds: ' + error);
    }
    this.fetchingBedsSubject.next(false);
  }

  syncVisibleBedsWithAllBeds() {
    this.visibleBeds = this.allBeds.slice(this.firstVisibleBedIndex, this.firstVisibleBedIndex + this.AMOUNT_BEDS_VISIBLE);
  }

  async fetchVoorraad(bed: BedWithVoorraad) {
    bed.loading = true;
    try {
      const retrievedStock = await this.plugService.findByLocation(bed.naam).toPromise();
      bed.voorraad = retrievedStock.sort((a, b) => a.plantName.localeCompare(b.plantName));
      bed.loading = false;
    } catch (error) {
      console.error('Unable to get voorraad for bednr ' + bed.naam + '\n' + error);
    }
  }

  loadMoreItemsBottom() {
    const bedsToAdd: BedWithVoorraad[] = this.allBeds.slice(
      this.firstVisibleBedIndex + this.visibleBeds.length,
      this.firstVisibleBedIndex + this.visibleBeds.length + 5
    );
    bedsToAdd.forEach(bed => this.visibleBeds.push(bed));
    const amountOfBedsToHide = Math.max(this.visibleBeds.length - this.AMOUNT_BEDS_VISIBLE, 0);
    this.visibleBeds.splice(0, amountOfBedsToHide);
    this.firstVisibleBedIndex += amountOfBedsToHide;
    bedsToAdd.forEach(bed => this.fetchVoorraad(bed));
  }

  loadMoreItemsTop() {
    const bedsToAdd: BedWithVoorraad[] = this.allBeds.slice(
      Math.max(0, this.firstVisibleBedIndex - 6),
      Math.max(5, this.firstVisibleBedIndex - 1)
    );
    bedsToAdd.reverse().forEach(bed => this.visibleBeds.unshift(bed));
    const amountOfBedsToHide = Math.max(this.visibleBeds.length - this.AMOUNT_BEDS_VISIBLE, 0);
    this.visibleBeds.splice(-amountOfBedsToHide, amountOfBedsToHide);
    this.firstVisibleBedIndex -= bedsToAdd.length;
    bedsToAdd.forEach(bed => this.fetchVoorraad(bed));
  }

  public async onAttach() {
    this.fetchAllBeds().then(() => {
      this.syncVisibleBedsWithAllBeds();
      this.fetchingBedsSubject.next(true);
      forkJoin(this.visibleBeds.map(bed => this.fetchVoorraad(bed)))
        .subscribe(() => {
          if (this.scrollPosition) {
            this.scrollContainer.nativeElement.scrollTo(0, this.scrollPosition);
          } else if (!this.searchTerm) {
            this.bedInput.nativeElement.focus();
          }
          this.fetchingBedsSubject.next(false);
        });
    });
  }

  private setupSearchFieldBehaviour() {
    this.searchTermSubject.pipe(
      takeUntil(this.onDestroy$),
      debounceTime(1000),
      filter(str => str && str.length >= 2),
      distinctUntilChanged(),
    ).subscribe(searchTerm => this.applyFilter(searchTerm));
  }

  private applyFilter(term: string) {
    this.visibleBeds = [];

    if (term && term.length >= 2) {
      term = term.trim().toLowerCase();

      const index = this.allBeds.findIndex(bed => {
        const bedNaam = bed.naam?.toLowerCase() || '';
        const matchesTerm = bedNaam.startsWith(term);

        if (this.selectedPosition) {
          const endsWithSelected = bedNaam.endsWith(this.selectedPosition.toLowerCase());
          return matchesTerm && endsWithSelected;
        }

        return matchesTerm;
      });

      if (index >= 0) {
        this.noBedsFoundMessage = undefined;
        this.firstVisibleBedIndex = index;
        this.syncVisibleBedsWithAllBeds();
        this.visibleBeds = this.visibleBeds.filter(bed => {
          const bedName = bed.naam?.toLowerCase() || '';

          if (this.selectedPosition) {
            return bedName.startsWith(term) && bedName.endsWith(this.selectedPosition.toLowerCase());
          }
          return bedName.startsWith(term);
        });

        this.visibleBeds.forEach(bed => this.fetchVoorraad(bed));
      } else {
        this.noBedsFoundMessage = 'Geen bedden gevonden voor zoekterm: ' + term;
      }
    } else {
      this.noBedsFoundMessage = undefined;
      this.visibleBeds = [];
    }
  }


  openAddStockDialog() {
    this.dialog.open(AddNewPlugStockDialogComponent, {
      width: '90%',
      height: '90%'
    })
      .afterClosed().subscribe(() => this.onAttach());
  }

  async setLastInspection(event: MouseEvent, stock: PlugStock, bed: BedWithVoorraad) {
    event.stopPropagation();
    await this.updateStockInspection(stock);
    await this.fetchVoorraad(bed);
  }

  async setLastInspectionForLocation(event: MouseEvent, bed: BedWithVoorraad) {
    event.stopPropagation();
    const updatePromises = bed.voorraad.map((stock: PlugStock) => this.updateStockInspection(stock));
    await Promise.all(updatePromises);
    await this.fetchVoorraad(bed);
  }

  private async updateStockInspection(stock: PlugStock) {
    if (this.previousDates.has(stock.id) &&
      (new Date(this.previousDates.get(stock.id)) < new Date() || this.previousDates.get(stock.id) === undefined)) {
      stock.lastInspection = this.previousDates.get(stock.id);
      this.previousDates.delete(stock.id);
    } else {
      this.previousDates.set(stock.id, stock.lastInspection);
      stock.lastInspection = new Date();
    }
    await this.plugService.update(stock).toPromise();
  }

  onSortToggleChange(checked: boolean) {
    this.useCustomSort = checked;
    this.applySorting();
  }

  private applySorting() {
    if (!this.allBeds) {
      return;
    }

    this.allBeds.sort((a, b) => {
      return this.useCustomSort
        ? this.sortingService.customLocationCompare(a.naam, b.naam)
        : a.naam.localeCompare(b.naam);
    });

    this.syncVisibleBedsWithAllBeds();
    this.reapplyFilter();
  }

  protected reapplyFilter() {
    if (this.searchTermSubject.value) {
      this.applyFilter(this.searchTermSubject.value);
    }
  }
}
