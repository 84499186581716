export enum LabelLocation {
  BORCULO = 'BORCULO',
  ENSCHEDE = 'ENSCHEDE',
  NORDKIRCHEN = 'NORDKIRCHEN',
  INKOOP = 'INKOOP',
  NOVATEC = 'NOVATEC',
  SPOED = 'SPOED',
  MEDDO = 'MEDDO',
  GREGG = 'GREGG',
  INKOOP_NORDKIRCHEN = 'INKOOP_NORDKIRCHEN'
}
