import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortingService {

  private parseLocation(str: string) {
    const match = str.match(/^(.+?)(\d+)([LR])?$/i);
    if (match) {
      const prefix = match[1];
      const numeric = parseInt(match[2], 10);
      const side = match[3] ?? '';
      return { prefix, numeric, side };
    }
    return { prefix: str, numeric: Number.MAX_VALUE, side: '' };
  }

  public customLocationCompare(a: string, b: string): number {
    const A = this.parseLocation(a);
    const B = this.parseLocation(b);

    const prefixComp = A.prefix.localeCompare(B.prefix, undefined, { numeric: true, sensitivity: 'base' });
    if (prefixComp !== 0) {
      return prefixComp;
    }

    const sideOrder = { 'L': 0, 'R': 1, '': 2 };
    const sideComp = sideOrder[A.side.toUpperCase()] - sideOrder[B.side.toUpperCase()];
    if (sideComp !== 0) {
      return sideComp;
    }

    return A.numeric - B.numeric;
  }
}
