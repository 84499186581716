export class Utils {
  /**
   * Checks if a given date is today.
   */
  static isToday(date?: Date): boolean {
    if (date == null) { return false; }
    const parsedDate = new Date(date);
    const today = new Date();
    return parsedDate.getFullYear() === today.getFullYear() &&
      parsedDate.getMonth() === today.getMonth() &&
      parsedDate.getDate() === today.getDate();
  }
}
