<h1>Label printen</h1>
<h2>{{data.articleName}}</h2>

<div class="button-container">
  <mat-button-toggle-group class="button-group" [vertical]="true" [(ngModel)]="printerLocation">
    <mat-button-toggle value="BORCULO">Borculo</mat-button-toggle>
    <mat-button-toggle value="ENSCHEDE">Enschede</mat-button-toggle>
    <mat-button-toggle value="NOKI">Nordkirchen</mat-button-toggle>
    <mat-button-toggle value="NEEDSEWEG">Needseweg</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<mat-dialog-actions>
  <button mat-raised-button (click)= "cancel()" >
    <i class="material-icons" aria-label="Annuleren">backspace</i>
    Annuleren
  </button>
  <button [disabled]="isDisabled()" mat-raised-button color="primary" (click)="onSubmitClick(printerLocation, data.id)">
    printen
    <mat-icon>printer</mat-icon>
  </button>
</mat-dialog-actions>

