<div class="container">
  <mat-card class="label-card">
    <mat-card-title>Labels Info</mat-card-title>
    <mat-divider></mat-divider>

    <button
      *ngFor="let label of labels"
      class="label-button"
      (click)="printLabel(label)"
      mat-ripple
      [matRippleColor]="'rgba(0,0,0,0.1)'"
    >
      <div class="label-info">
        <span class="label-name">{{ label.name }}:</span>
        <span
          [ngClass]="{ 'active': label.count > 0, 'inactive': label.count === 0 }"
        >
          {{ label.count }} labels
        </span>
      </div>
      <mat-icon class="print-icon">print</mat-icon>
    </button>

    <button mat-raised-button color="primary" (click)="refresh()" class="refresh-btn">Verversen</button>
  </mat-card>
</div>

