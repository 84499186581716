import {Injectable} from '@angular/core';
import {BaseService} from '../common/base.service';
import {catchError} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Injectable()
export class DashboardService extends BaseService {

  private dashboardUrl = this.baseUrl + '/dashboard';

  getLabelsPerLocation(): Observable<Map<string, number>> {
    return this.http.get<Map<string, number>>(this.dashboardUrl).pipe(
      catchError(error => {
        console.error('Error fetching data:', error);
        return of(new Map<string, number>());
      })
    );
  }
}
