import {Injectable} from '@angular/core';
import {PlugStock} from '../voorraad/stek/plugStock';
import {Stock} from '../voorraad/stock';
import {SalesOrderItem} from '../checklist/sales-order-item';
import {OpenGroundStock} from '../voorraad/vollegrond/openGroundStock';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BarcodeService} from './barcode.service';
import EntityNameAndId from './EntityNameAndId';
import {ChooseStockToNavigateDialogComponent} from './choose-stock-to-navigate-dialog/choose-stock-to-navigate-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class BarcodeScannerService {

  private readonly _salesOrderItem = 'SalesOrderItem';
  private readonly _potStock = 'PotStock';
  private readonly _plugStock = 'PlugStock';
  private readonly _openGroundStock = 'OpenGroundStock';
  private readonly _openGroundProductionJob = 'OpenGroundProductionJob';
  private readonly _pottingProductionJob = 'PottingProductionJob';

  constructor(
    private barcodeService: BarcodeService,
    private _toastr: ToastrService,
    private _router: Router,
    private _dialog: MatDialog
  ) {
  }

  nextBarcode(barcode: number) {
    this.barcodeService.getEntityNameAndIdByBarcode(barcode).subscribe({
      next: (entityNameAndIds: EntityNameAndId[]) => {
        if (entityNameAndIds !== undefined && entityNameAndIds.length === 1) {
          this.handleEntityByType(barcode, entityNameAndIds[0]);
        } else if (entityNameAndIds !== undefined && entityNameAndIds.length > 1) {
          this.openUserSelectionDialogBeforeNavigating(entityNameAndIds, barcode);
        }
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  private handleEntityByType(barcode: number, entityNameAndId: EntityNameAndId): void {
    console.log(entityNameAndId);
    switch (entityNameAndId.entityName) {
      case this._salesOrderItem:
        console.log('MATCH FOUND: SalesOrderItem!');
        this.handleSalesOrderItem(barcode, entityNameAndId);
        break;
      case this._openGroundStock:
        this.navigateToOpenGroundStock(entityNameAndId.entityId);
        break;
      case this._plugStock:
        this.navigateToPlugStock(entityNameAndId.entityId);
        break;
      case this._potStock:
        this.navigateToPotStock(entityNameAndId.entityId);
        break;
      case this._openGroundProductionJob:
        this.navigateToOpenGroundProductionJob(entityNameAndId.entityId);
        break;
      case this._pottingProductionJob:
        this.navigateToPottingProductionJob(entityNameAndId.entityId);
        break;
      default:
        console.log('NO MATCH, default case hit:', entityNameAndId.entityName);
        this.handleUnknownObject();
        break;
    }
  }

  private handleSalesOrderItem(barcode: number, item: EntityNameAndId): void {
    console.log(item);
    this.barcodeService.getSalesOrderByBarcode(barcode).subscribe({
      next: (salesOrderId) => {
        this._toastr.success('Orderregel gevonden!');
        this.navigateToCheckingList(salesOrderId, item.entityId);
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  private handleUnknownObject() {
    this._toastr.error('Onbekend object type');
  }

  private handleError(error: any) {
    console.error('Error:', error);
    this._toastr.error('Barcode niet gevonden');
  }

  private navigateToCheckingList(orderId: number, orderItemId: number) {
    this._router.navigate(['afchecklijst', orderId], {queryParams: {orderItemId: orderItemId, device: 'scanner'}});
  }

  private navigateToPotStock(stockId: number) {
    this._router.navigate(['voorraad', 'potten', stockId]);
  }

  private navigateToOpenGroundStock(stockId: number) {
    this._router.navigate(['voorraad', 'vollegrond', stockId]);
  }

  private navigateToPlugStock(stockId: number) {
    this._router.navigate(['voorraad', 'stek', stockId]);
  }

  private navigateToOpenGroundProductionJob(productionJobId: number) {
    this._router.navigate(['voorraad', 'vollegrond', 'uitplant-opdrachten'], {queryParams: {productionJobId: productionJobId}});
  }

  private navigateToPottingProductionJob(productionJobId: number) {
    this._router.navigate(['voorraad', 'potten', 'oppot-opdrachten'], {queryParams: {productionJobId: productionJobId}});
  }

  private openUserSelectionDialogBeforeNavigating(entityNameAndIds: EntityNameAndId[], barcode: number) {
    const stockIds: number[] = entityNameAndIds.map(entity => entity.entityId);

    const dialogRef = this._dialog.open(ChooseStockToNavigateDialogComponent, {
      width: '400px',
      data: {ids: stockIds}
    });

    dialogRef.afterClosed().subscribe((selectedStockId) => {
      if (selectedStockId) {
        const selectedEntity = entityNameAndIds.find(entity => entity.entityId === selectedStockId);

        if (selectedEntity) {
          this.handleEntityByType(barcode, selectedEntity);
        } else {
          console.warn('No matching entity found for the selected stock ID:', selectedStockId);
        }
      }
    });
  }
}
