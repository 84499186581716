import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PotService} from '../../voorraad/pot/pot.service';
import {forkJoin} from 'rxjs';
import {PotStock} from '../../voorraad/pot/pot';

@Component({
  selector: 'app-choose-stock-to-navigate-dialog',
  templateUrl: './choose-stock-to-navigate-dialog.component.html',
  styleUrls: ['./choose-stock-to-navigate-dialog.component.scss']
})
export class ChooseStockToNavigateDialogComponent implements OnInit {
  stocks: PotStock[] = [];
  isLoading = true;

  constructor(
    private stockService: PotService,
    public dialogRef: MatDialogRef<ChooseStockToNavigateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { ids: number[] }
  ) {}

  ngOnInit(): void {
    const stockRequests = this.data.ids
      .map(id => this.stockService.getStockById(id));

    forkJoin(stockRequests).subscribe({
      next: (stocks) => {
        this.stocks = stocks;
        this.isLoading = false;
      },
      error: (err) => {
        console.error('Error fetching stocks:', err);
        this.isLoading = false;
      }
    });
  }

  onSelectStock(stockId: number): void {
    this.dialogRef.close(stockId);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
