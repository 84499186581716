import { Component, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { PrinterSelectionDialogComponent } from './printer-selection-dialog/printer-selection-dialog.component';
import { PrinterLocation } from '../checklist/order-item-overview/printing-dialog/PrinterLocation';
import { LabelLocation } from './LabelLocation';
import { LabelPrintService } from '../checklist/order-item-overview/printing-dialog/label-print/label-print.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

interface Label {
  name: string;
  count: number;
}

const LABEL_KEY_MAP: { [key: string]: string } = {
  'Borculo': 'LB',
  'Enschede': 'LE',
  'Nordkirchen': 'LN',
  'Nordkirchen inkoop': 'LD',
  'Gregg': 'LG',
  'Borculo inkoop': 'LI',
  'Meddo': 'LM',
  'NovaTech': 'LZ',
  'Spoed': 'LS'
};

const LABEL_LOCATION_MAP: { [key: string]: LabelLocation } = {
  'Borculo': LabelLocation.BORCULO,
  'Enschede': LabelLocation.ENSCHEDE,
  'Nordkirchen': LabelLocation.NORDKIRCHEN,
  'Nordkirken inkoop': LabelLocation.INKOOP_NORDKIRCHEN,
  'Gregg': LabelLocation.GREGG,
  'Borculo inkoop': LabelLocation.INKOOP,
  'Meddo': LabelLocation.MEDDO,
  'NovaTech': LabelLocation.NOVATEC,
  'Spoed': LabelLocation.SPOED
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  labels: Label[] = Object.keys(LABEL_KEY_MAP).map(name => ({ name, count: 0 }));

  constructor(
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private labelPrintService: LabelPrintService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.dashboardService.getLabelsPerLocation().subscribe({
      next: (response) => this.updateLabelCounts(new Map(Object.entries(response))),
      error: (error) => this.handleError('Fout bij het ophalen van labels', error)
    });
  }

  private updateLabelCounts(map: Map<string, number>): void {
    this.labels.forEach(label => {
      const key = LABEL_KEY_MAP[label.name];
      label.count = map.get(key) ?? 0;
    });
  }

  printLabel(label: Label): void {
    const labelLocation = LABEL_LOCATION_MAP[label.name];

    if (!labelLocation) {
      this.toastr.error('Ongeldige labelconfiguratie');
      return;
    }

    this.openPrinterDialog().afterClosed().subscribe((selectedPrinter: PrinterLocation | null) => {
      if (selectedPrinter) {
        this.sendPrintJob(labelLocation, selectedPrinter);
      }
    });
  }

  private openPrinterDialog() {
    return this.dialog.open(PrinterSelectionDialogComponent);
  }

  private sendPrintJob(labelLocation: LabelLocation, printer: PrinterLocation): void {
    this.labelPrintService.printLabelsForLocation(labelLocation, printer)
      .pipe(finalize(() => this.refresh()))
      .subscribe({
        next: () => this.toastr.success('Labels succesvol afgedrukt'),
        error: (error) => this.handleError('Fout bij verzenden van printopdracht', error)
      });
  }

  private handleError(message: string, error: any): void {
    console.error(message, error);
    this.toastr.error(message);
  }
}
