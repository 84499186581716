import {Injectable} from '@angular/core';
import {BaseService} from '../../common/base.service';
import {Observable} from 'rxjs';
import {catchError, mapTo} from 'rxjs/operators';
import {OpenGroundStock} from './openGroundStock';

@Injectable({
  providedIn: 'root'
})
export class OpenGroundStockService extends BaseService {

  private url = this.baseUrl + '/vollegrond';

  findByCodeOrName(searchTerm: string): Observable<OpenGroundStock[]> {
    return this.http.get<OpenGroundStock[]>(this.url + '/search?terms=' + encodeURIComponent(searchTerm))
      .pipe(catchError(this.handleError('zoeken van vollegrond partijen', <OpenGroundStock[]>[] ,
        'Fout bij ophalen van voorraad waarbij zoekterm ' + searchTerm + ' deel uitmaakt van naam of code')));
  }

  getById(key: number): Observable<OpenGroundStock> {
    return this.http.get<OpenGroundStock>(this.url + '/' + key)
      .pipe(catchError(this.handleError('ophalen van vollegrond partijen', undefined, 'Fout bij ophalen van voorraad met id ' + key)));
  }

  getStockByLocation(location: string): Observable<OpenGroundStock[]> {
    return this.http.get<OpenGroundStock[]>(this.url + '/location/search?terms=' + encodeURIComponent(location))
      .pipe(catchError(this.handleError('ophalen van pot-voorraadpartij aan de hand van bednummer', [])));
  }

  getAll(): Observable<OpenGroundStock[]> {
    return this.http.get<OpenGroundStock[]>(this.url)
      .pipe(catchError(this.handleError('ophalen van alle vollegrond partijenen', [])));
  }

  update(vollegrond: OpenGroundStock): Observable<OpenGroundStock> {
    return this.http.put(this.url + '/' + vollegrond.id, vollegrond)
      .pipe(catchError(this.handleError('opslaan van vollegrond partij', undefined)));
  }

  delete(vollegrond: OpenGroundStock): Observable<void> {
    return this.http.delete(this.url + '/' + vollegrond.id)
      .pipe(
        mapTo(void 0),
      );
  }

}
