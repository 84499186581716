import {Injectable} from '@angular/core';
import {BaseService} from '../../../../common/base.service';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {PrinterLocation} from '../PrinterLocation';
import {LabelLocation} from '../../../../dashboard/LabelLocation';

@Injectable({
  providedIn: 'root'
})
export class LabelPrintService extends BaseService {

  private url = this.baseUrl + '/label';

  printLabel(printer: PrinterLocation, salesOrderItemId: number): Observable<any[] | string> {
    return this.http.put(this.url + `/print-sales-order-item?printerLocation=${printer}&salesOrderItemId=${salesOrderItemId}`,
      {},
      {responseType: 'text'})
      .pipe(catchError(this.handleError('Printen', [])));
  }

  printLabelsForLocation(labelLocation: LabelLocation, printer: PrinterLocation): Observable<void> {
    return this.http.put<void>(`${this.url}/print?labelLocation=${labelLocation}&printerLocation=${printer}`, {})
      .pipe(
        catchError(error => {
          console.error('Error printing labels:', error);
          return of(undefined);
        })
      );
  }
}
