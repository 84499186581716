import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {SalesOrderService} from '../../sales-order.service';
import {BeepService} from '../../../_services/beep.service';
import {SalesOrderItem} from '../../sales-order-item';
import {SalesOrder} from '../../sales-order';
import {faBarcode} from '@fortawesome/free-solid-svg-icons';
import {OrderDetailOpmDialogComponent} from '../order-detail-opm-dialog/order-detail-opm-dialog.component';
import {ToastrService} from 'ngx-toastr';
import {ChangeBonDialogComponent} from '../change-bon-dialog/change-bon-dialog.component';
import {asyncScheduler, BehaviorSubject, Subject, Subscription} from 'rxjs';
import {buffer, debounceTime, filter, map, takeUntil, tap, throttleTime} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ItemCancelledDialogComponent} from '../item-cancelled-dialog/item-cancelled-dialog.component';
import {PrintingDialogComponent} from '../../order-item-overview/printing-dialog/printing-dialog.component';
import {LocationService} from '../../../_services/location.service';
import {BarcodeService} from '../../../barcode-scanner/barcode.service';
import EntityNameAndId from '../../../barcode-scanner/EntityNameAndId';

export interface ScanDialogResult {
  orderId: number;
  itemSleutel: number;
}

@Component({
  selector: 'app-barcode-scanner-dialog',
  templateUrl: './barcode-scanner-dialog.component.html',
  styleUrls: ['./barcode-scanner-dialog.component.scss']
})
export class BarcodeScannerDialogComponent implements OnDestroy, OnInit {

  private readonly _salesOrderItem = 'SalesOrderItem';

  commentsDialogIsOpen = false;
  printingDialogIsOpen = false;
  faBarcode = faBarcode;
  _item: SalesOrderItem;
  backupItem: SalesOrderItem;
  previousItem: SalesOrderItem;
  order: SalesOrder;
  subscriptions = new Subscription();
  barcodeInputSubject = new Subject<number>();
  barcodeInput$ = this.barcodeInputSubject.asObservable();

  saveItemSubject = new Subject<void>();
  saveItem$ = this.saveItemSubject.asObservable();

  savingInProgressSubject = new BehaviorSubject<boolean>(false);
  savingInProgress$ = this.savingInProgressSubject.asObservable();
  cancelSave$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ScanDialogResult>,
    private salesOrderService: SalesOrderService,
    private beepService: BeepService,
    private _dialog: MatDialog,
    private _toastr: ToastrService,
    private _router: Router,
    private locationService: LocationService,
    private barcodeService: BarcodeService
  ) {
    this.order = data.order;
    this.item = data.item;
  }

  ngOnInit() {
    this.setupBarcodeScanning();
    this.setupItemSaving();
  }

  get item(): SalesOrderItem {
    return this._item;
  }

  set item(nextItem: SalesOrderItem) {
    if (this.item) {
      this.previousItem = this.item;
      if (this.item.orderAmount === 0 && (this.item.scannedVmh > 0 || this.item.scannedVml > 0)) {
        this._dialog.open(ItemCancelledDialogComponent);
      }
    }
    this._item = nextItem;
    this.backupItem = {...this._item};
  }

  // Dubbele code met order-detail.component
  openPrintingDialog(salesOrderItem: SalesOrderItem) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    const dialogRef = this._dialog.open(PrintingDialogComponent, {
      width: '600px',
      height: '600px',
      data: salesOrderItem
    });
    this.printingDialogIsOpen = true;
    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
      this.printingDialogIsOpen = false;
    }));
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (this.printingDialogIsOpen === false) {
      if (event.defaultPrevented) {
        return; // Should do nothing if the default action has been cancelleds
      }
      if (this.commentsDialogIsOpen === false) {
        const number = Number(event.key);
        if (number >= 0) {
          this.barcodeInputSubject.next(number);
          event.preventDefault();
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async askToSwitchBon(orderId: number) {
    try {
      const order = await this.salesOrderService.getSalesOrderForCheckList(orderId).toPromise();
      const changeBonDialog = this._dialog.open(ChangeBonDialogComponent, {data: {order: order}});
      return await changeBonDialog.afterClosed().toPromise();
    } catch (error) {
      this._toastr.error('Kon de bijbehorende bon niet openen');
      console.error(error);
    }
  }

  goToPrevious() {
    this.item = this.previousItem;
  }

  checkRestLaag() {
    if (this.itemNotChecked(this.item)) {
      this.item.scannedVml = this.item.neededLabels - (this.item.scannedVmh + this.item.scannedMerged);
      this.checkIfComplete();
      this.saveItemSubject.next();
    }
  }

  checkRestHoog() {
    if (this.itemNotChecked(this.item)) {
      this.item.scannedVmh = this.item.neededLabels - (this.item.scannedVml + this.item.scannedMerged);
      this.checkIfComplete();
      this.saveItemSubject.next();
    }
  }

  checkRestSamen() {
    if (this.itemNotChecked(this.item)) {
      this.item.scannedMerged = this.item.neededLabels - (this.item.scannedVml + this.item.scannedVmh);
      this.checkIfComplete();
      this.saveItemSubject.next();
    }
  }

  vmlChanged(newValue: number) {
    this.item.scannedVml = newValue;
    this.saveItemSubject.next();
  }

  vmhChanged(newValue: number) {
    this.item.scannedVmh = newValue;
    this.saveItemSubject.next();
  }

  mergedBoxesChanged(newValue: number) {
    this.item.scannedMerged = newValue;
    this.saveItemSubject.next();
  }

  private itemNotChecked(item: SalesOrderItem) {
    return item.neededLabels > (item.scannedVml + item.scannedVmh + item.scannedMerged);
  }

  openOpmerkingenDialog(item: SalesOrderItem): void {
    const dialogRef = this._dialog.open(OrderDetailOpmDialogComponent, {
      width: '250px',
      data: item
    });
    this.commentsDialogIsOpen = true;
    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveOrderItem();
      }
      this.commentsDialogIsOpen = false;
    }));
  }

  cancelSave() {
    this.cancelSave$.next(true);
    this.resetItem();
  }

  cancel() {
    this.dialogRef.close();
  }

  private setupBarcodeScanning() {
    this.subscriptions.add(this.barcodeInput$.pipe(
      buffer(this.barcodeInput$.pipe(throttleTime(200, asyncScheduler, {leading: false, trailing: true}))),
      filter(barcodeArray => barcodeArray.length > 3),
      map(barcodeArray => +barcodeArray.join(''))
    ).subscribe({
      next: (barcode: number) => {
        this.nextBarcode(barcode);
      }
    }));
  }

  private async nextBarcode(barcode: number) {
    if (this.savingInProgressSubject.getValue()) {
      await this.savingInProgress$.toPromise();
    }
    const barcodeEntities: EntityNameAndId[] = await this.barcodeService.getEntityNameAndIdByBarcode(barcode).toPromise();

    if (!barcodeEntities || barcodeEntities.length !== 1) {
      this._toastr.error('Barcode is niet geldig of komt niet overeen met een enkel item.');
      return;
    }

    const barcodeEntity = barcodeEntities[0];

    if (barcodeEntity.entityName !== this._salesOrderItem) {
      this._toastr.error('Geen paklabel barcode gevonden');
      return;
    }

    const index = this.order?.items.findIndex(i => i.id === barcodeEntity.entityId);
    if (index >= 0) {
      if (barcodeEntity.entityName !== this._salesOrderItem) {
        this._toastr.error('Gevonden barcode is niet van een orderregel');
        return;
      }
      this.item = await this.barcodeService.getSalesOrderItemByBarcode(barcode).toPromise();
    } else {
      try {
        const orderId = await this.barcodeService.getSalesOrderByBarcode(barcode).toPromise();
        if (this.order === undefined || this.order.id === orderId || await this.askToSwitchBon(orderId)) {
          this.dialogRef.close();
          this.navigateToAfchecklijst(orderId, barcodeEntity.entityId);
        }
      } catch (error) {
        this._toastr.error('Kon geen bon of regel vinden met deze scan. Is het label vies? Probeer het nog eens');
      }
    }
  }

  private setupItemSaving() {
    this.subscriptions.add(this.saveItem$.pipe(
      tap(() => this.checkIfComplete()),
      debounceTime(500)
    ).subscribe(() => this.saveOrderItem()));
  }

  private async saveOrderItem() {
    if (!this.savingInProgressSubject.getValue()) {
      this.savingInProgressSubject.next(true);
      this._toastr.info('Bezig', 'Opslaan', {timeOut: 1000});
      try {
        this._item = await this.salesOrderService.updateSalesOrderItem(this.item)
          .pipe(takeUntil(this.cancelSave$)).toPromise();
        this._toastr.success('Opgeslagen');
        this.order.items = await this.salesOrderService.getSalesOrderItemsFoSalesOrderId(this.order.id).toPromise();
        if (this.order.items
          .filter(item => this.locationService.isBedNrFromSelectedLocation(item.location))
          .every(item => item.allLabelsScanned)) {
          this.beepService.completed();
        }
      } catch (error) {
        this.resetItem();
        console.error('Unable to save CheckListItem:\n' + error);
        this._toastr.error('Fout bij opslaan! Probeer het nog eens');
        this.beepService.error();
      } finally {
        this.savingInProgressSubject.next(false);
      }
    } else {
      console.warn('Skipped save');
    }
  }

  private resetItem() {
    this._item = {...this.backupItem};
  }

  private checkIfComplete() {
    this.item.allLabelsScanned = this.item.scannedVml + this.item.scannedVmh + this.item.scannedMerged === this.item.neededLabels;
  }

  manuallyChecked() {
    this.item.allLabelsScanned = true;
    this.saveOrderItem();
  }

  navigateToAfchecklijst(orderId: number, orderItemId: number) {
    this._router.navigate(['afchecklijst', orderId], {queryParams: {orderItemId: orderItemId, device: 'scanner'}});
  }
}
