<div class="loading-shade" *ngIf="fetchingBeds$ | async">
  <mat-spinner></mat-spinner>
</div>

<div class="counting-location-filter" aria-orientation="horizontal">
  <mat-form-field id="search-field" appearance="outline">
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Bednummer</mat-label>
    <input #bedInput matInput type="search" placeholder="Bednummer" autofocus [(ngModel)]="searchTerm"
           (keyup)="searchTermSubject.next(searchTerm)" (keyup.enter)="searchTermSubject.next(searchTerm)">
    <button mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="resetSearchField()">
      <mat-icon>&#xE5CD;</mat-icon>
    </button>
  </mat-form-field>
  <h3>Regels: {{calculateAmountOfRows()}}</h3>
</div>
<div class="priority-slider-box" aria-orientation="horizontal">
  <mat-slider
    class="priority-slider"
    thumbLabel
    [tickInterval]="1"
    [displayWith]="formatThumbLabel"
    [step]="10"
    [min]="0"
    [max]="1000"
    [(ngModel)]="higherThanPriority"
    (change)="sortBedsOnLocationWithSlider()"
    aria-label="units"></mat-slider>
</div>
<div class="toggle-container">
  <mat-slide-toggle labelPosition="before" [(ngModel)]="useCustomSort" (change)="onSortToggleChange($event.checked)">
    Pad-sortering
  </mat-slide-toggle>
</div>
<div id="search-result" *ngIf="visibleBeds && visibleBeds.length > 0">
  <div id="search-result-header">
    <div nameCol>Naam</div>
    <div otherCol>Totaal</div>
    <div otherCol>Vrij</div>
    <div otherCol>VR</div>
    <div otherCol>Maat</div>
    <div otherCol>Tellen</div>
  </div>
  <div #scrollContainer id="search-result-beds">
    <button mat-raised-button id="previous-beds-button" class="more-beds-button" (click)="loadMoreItemsTop()">
      <mat-icon aria-label="Load more">expand_less</mat-icon>
      vorige bedden
    </button>
    <mat-card class="bed" *ngFor="let bed of visibleBeds">
      <mat-card-title class="description">
        <span>{{bed.location}}</span>
        <button mat-icon-button aria-label="Button to set today as last day seen"
                (click)="setLastInspectionForLocation($event, bed)">
          <mat-icon>preview</mat-icon>
        </button>
      </mat-card-title>
      <mat-card-content>
        <mat-list class="voorraadlijst">
          <mat-list-item class="voorraadpartij" *ngFor="let voorraad of bed.stocks"
                         [class.counted]="Utils.isToday(voorraad.lastInspection)" (click)="navigateToStock(voorraad)">
            <div nameCol>{{voorraad.plantName}}</div>
            <div otherCol>{{voorraad.total}}</div>
            <div otherCol>{{voorraad.amountFitForSale}}</div>
            <div otherCol>{{voorraad.status}}</div>
            <div otherCol>{{voorraad.unitSizeName}}</div>
            <div otherCol>
              <button mat-icon-button aria-label="Button to set today as last day seen"
                      (click)="setLastInspection($event, voorraad)">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
    <button mat-raised-button id="next-beds-button" class="more-beds-button" (click)="loadMoreItemsBottom()">
      <mat-icon aria-label="Load more">expand_more</mat-icon>
      volgende bedden
    </button>
  </div>
</div>
<div *ngIf="noBedsFoundMessage">{{noBedsFoundMessage}}</div>
