import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';
import localeNL from '@angular/common/locales/nl';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';

import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './routing/app-routing.module';
import {PotCountComponent} from './voorraad/pot/pot-count/pot-count.component';
import {environment} from '../environments/environment';
import {PotDetailComponent} from './voorraad/pot/pot-detail/pot-detail.component';
import {CustomReuseStrategy} from './routing/CustomReuseStrategy';
import {AppRouterOutletDirective} from './routing/AppRouterOutletDirective';
import {RouteReuseStrategy} from '@angular/router';
import {SidenavComponent} from './sidenav/sidenav.component';
import {PotSearchComponent} from './voorraad/pot/pot-search/pot-search.component';
import {PortaalComponent} from './portaal/portaal.component';
import {LoginComponent} from './login/login.component';
import {ChangeVrijDialogComponent} from './voorraad/pot/pot-detail/change-vrij-dialog/change-vrij-dialog.component';
import {VoorraadDeleteDialogComponent} from './voorraad/pot/pot-detail/delete-dialog/voorraad-delete-dialog.component';
import {OptionsVrijDialogComponent} from './voorraad/pot/pot-detail/options-vrij-dialog/options-vrij-dialog.component';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {MenuListItemComponent} from './sidenav/menu-list-item/menu-list-item.component';
import {PlugStockSearchComponent} from './voorraad/stek/stek-search/plug-stock-search.component';
import {PlugStockDetailComponent} from './voorraad/stek/stek-detail/plug-stock-detail.component';
import {PlugStockDeleteDialogComponent} from './voorraad/stek/stek-detail/stek-delete-dialog/plug-stock-delete-dialog.component';
import {OpenGroundStockSearchComponent} from './voorraad/vollegrond/vollegrond-search/open-ground-stock-search.component';
import {OpenGroundStockDetailComponent} from './voorraad/vollegrond/vollegrond-detail/open-ground-stock-detail.component';
import {
  OpenGroundStockDeleteDialogComponent
} from './voorraad/vollegrond/vollegrond-delete-dialog/open-ground-stock-delete-dialog.component';
import {OpenGroundStockGrubDialogComponent} from './voorraad/vollegrond/vollegrond-rooi-dialog/open-ground-stock-grub-dialog.component';
import {ReleaseAfroepDialogComponent} from './voorraad/pot/pot-detail/release-afroep-dialog/release-afroep-dialog.component';
import {PwaControlsComponent} from './sidenav/pwa-controls/pwa-controls.component';
import {CustomerOverviewComponent} from './checklist/customer-overview/customer-overview.component';
import {OrderDetailComponent} from './checklist/order-detail/order-detail.component';
import {PotService} from './voorraad/pot/pot.service';
import {ReservationService} from './reservation/reservation.service';
import {PlugStockService} from './voorraad/stek/plug-stock.service';
import {PwaService} from './_services/pwa.service';
import {CounterComponent} from './common/counter/counter.component';
import {OrderDetailOpmDialogComponent} from './checklist/order-detail/order-detail-opm-dialog/order-detail-opm-dialog.component';
import {LocationService} from './_services/location.service';
import {LocationSelectorComponent} from './common/location-selector/location-selector.component';
import {SalesOrderItemOverviewComponent} from './checklist/order-item-overview/sales-order-item-overview.component';
import {MoveStockDialog} from './voorraad/move-stock-dialog/move-stock-dialog';
import {RegisterTotalsComponent} from './checklist/register-totals/register-totals.component';
import {HttpErrorInterceptor} from './_helpers/http-error.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {ScanBonDialogComponent} from './checklist/customer-overview/scan-bon-dialog/scan-bon-dialog.component';
import {ScannerComponent} from './common/scanner/scanner.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BeepService} from './_services/beep.service';
import {ScanOrderItemDialogComponent} from './checklist/order-detail/scan-order-item-dialog/scan-order-item-dialog.component';
import {BarcodeScannerDialogComponent} from './checklist/order-detail/barcode-scanner-dialog/barcode-scanner-dialog.component';
import {ChangeBonDialogComponent} from './checklist/order-detail/change-bon-dialog/change-bon-dialog.component';
import {SearchMoreComponent} from './voorraad/common/search-more/search-more.component';
import {SplitDialogComponent} from './voorraad/pot/pot-detail/split-dialog/split-dialog.component';
import {MatButtonLoadingDirective} from './common/mat-button-loading.directive';
import {ScanPrintListComponent} from './checklist/scan-print-list/scan-print-list.component';
import {ScanPrintItemComponent} from './checklist/scan-print-list/scan-print-item/scan-print-item.component';
import {ReversePipe} from './_helpers/reverse.pipe';
import {SalesOrderCompleteDialogComponent} from './checklist/order-complete/order-complete/sales-order-complete-dialog.component';
import {ItemCancelledDialogComponent} from './checklist/order-detail/item-cancelled-dialog/item-cancelled-dialog.component';
import {StaleUpdateDialogComponent} from './common/stale-update-dialog/stale-update-dialog.component';
import {AddNewStockDialogComponent} from './voorraad/pot/add-new-stock-dialog/add-new-stock-dialog.component';
import {CounterTextComponent} from './common/counter-text-input/counter-text.component';
import {PrintingDialogComponent} from './checklist/order-item-overview/printing-dialog/printing-dialog.component';
import {MatRadioModule} from '@angular/material/radio';
import {LabelPrintService} from './checklist/order-item-overview/printing-dialog/label-print/label-print.service';
import {PotCountPriorityComponent} from './voorraad/pot/pot-count-priority/pot-count-priority.component';
import {HasReservationsDialogComponent} from './voorraad/pot/pot-detail/has-reservations-dialog/has-reservations-dialog.component';
import {PotJobsComponent} from './voorraad/pot/pot-jobs/pot-jobs.component';
import {PotProductionJobService} from './voorraad/pot/potting-production-job.service';
import {CreateStockDialogComponent} from './voorraad/pot/pot-jobs/create-stock-dialog/create-stock-dialog.component';
import {AddHyphenAfterTwoDigitsDirective} from './common/hyphen-directive/add-hyphen-after-two-digits.directive';
import {ProductionJobFilterPipe} from './_helpers/producion-job-filter.pipe';
import {UnitsizeAutocompleteFieldComponent} from './common/unitsize-autocomplete-field/unitsize-autocomplete-field.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {UnitsizeService} from './common/unitsize-autocomplete-field/unitsize.service';
import {PotStockCountAndPlaceComponent} from './voorraad/pot/pot-stock-count-and-place/pot-stock-count-and-place.component';
import {
  AssignLocationDialogComponent
} from './voorraad/pot/pot-stock-count-and-place/assign-location-dialog/assign-location-dialog.component';
import {StekCountComponent} from './voorraad/stek/stek-count/stek-count.component';
import {PlantService} from './plant/plant.service.tx';
import {PlantingJobsComponent} from './voorraad/vollegrond/planting-jobs/planting-jobs.component';
import {OpenGroundProductionJobService} from './voorraad/vollegrond/open-ground-production-job.service';
import {
  CreateOpenGroundStockDialogComponent
} from './voorraad/vollegrond/planting-jobs/create-stock-dialog/create-open-ground-stock-dialog.component';
import {PotHistoryComponent} from './voorraad/pot/pot-history/pot-history.component';
import {WarningMessageComponent} from './common/warning-message/warning-message.component';
import {ReservedStockOverviewComponent} from './voorraad/common/reserved-stock-overview/reserved-stock-overview.component';
import {PotControlledCountComponent} from './voorraad/pot/pot-controlled-count/pot-controlled-count.component';
import {AutoCompleteTextFieldComponent} from './common/auto-complete-text-field/auto-complete-text-field.component';
import {AddNewPlugStockDialogComponent} from './voorraad/stek/add-new-stock-dialog/add-new-plug-stock-dialog.component';
import {BarcodeScannerComponent} from './barcode-scanner/barcode-scanner.component';
import {ScanBarcodeWithCameraDialog} from './barcode-scanner/scan-barcode-with-camera-dialog/scan-barcode-with-camera-dialog.component';
import {ProductionJobResolverService} from './voorraad/pot/pot-jobs/create-stock-dialog/production-job-resolver.service';
import {ProductionJobService} from './voorraad/pot/production-job.service';
import {StockService} from './voorraad/stock.service';
import {DashboardComponent} from './dashboard/dashboard.component';
import {DashboardService} from './dashboard/dashboard.service';
import {MatRippleModule} from '@angular/material/core';
import {PrinterSelectionDialogComponent} from './dashboard/printer-selection-dialog/printer-selection-dialog.component';
import {ChooseStockToNavigateDialogComponent} from './barcode-scanner/choose-stock-to-navigate-dialog/choose-stock-to-navigate-dialog.component';
import {ProductionLocationPipe} from './voorraad/pot/pot-jobs/production-location.pipe';

registerLocaleData(localeNL, 'nl');

@NgModule({
  declarations: [
    AppComponent, PotCountComponent, PotCountPriorityComponent, PotDetailComponent,
    VoorraadDeleteDialogComponent, SidenavComponent,
    PotSearchComponent, PortaalComponent,
    AppRouterOutletDirective,
    ChangeVrijDialogComponent,
    LoginComponent,
    OptionsVrijDialogComponent,
    MenuListItemComponent,
    PlugStockSearchComponent,
    PlugStockDetailComponent,
    PlugStockDeleteDialogComponent,
    OpenGroundStockSearchComponent,
    OpenGroundStockDetailComponent,
    OpenGroundStockDeleteDialogComponent,
    OpenGroundStockGrubDialogComponent,
    ReleaseAfroepDialogComponent,
    PwaControlsComponent,
    CustomerOverviewComponent,
    OrderDetailComponent,
    CounterComponent,
    CounterTextComponent,
    SalesOrderItemOverviewComponent,
    OrderDetailOpmDialogComponent,
    LocationSelectorComponent,
    MoveStockDialog,
    RegisterTotalsComponent,
    ScanBonDialogComponent,
    ScannerComponent,
    BarcodeScannerDialogComponent,
    ScanOrderItemDialogComponent,
    ChangeBonDialogComponent,
    SearchMoreComponent,
    SplitDialogComponent,
    MatButtonLoadingDirective,
    ScanPrintListComponent,
    ScanPrintItemComponent,
    ReversePipe,
    SalesOrderCompleteDialogComponent,
    ItemCancelledDialogComponent,
    StaleUpdateDialogComponent,
    AddNewStockDialogComponent,
    PotCountPriorityComponent,
    PrintingDialogComponent,
    HasReservationsDialogComponent,
    PotJobsComponent,
    CreateStockDialogComponent,
    AddHyphenAfterTwoDigitsDirective,
    ProductionJobFilterPipe,
    UnitsizeAutocompleteFieldComponent,
    PotStockCountAndPlaceComponent,
    AssignLocationDialogComponent,
    StekCountComponent,
    AddNewPlugStockDialogComponent,
    PlantingJobsComponent,
    CreateOpenGroundStockDialogComponent,
    PotHistoryComponent,
    WarningMessageComponent,
    AutoCompleteTextFieldComponent,
    ReservedStockOverviewComponent,
    PotControlledCountComponent,
    BarcodeScannerComponent,
    ScanBarcodeWithCameraDialog,
    DashboardComponent,
    PrinterSelectionDialogComponent,
    ChooseStockToNavigateDialogComponent,
    ProductionLocationPipe
  ],
  imports: [
    BrowserModule,
    FormsModule, ReactiveFormsModule,
    AppRoutingModule,
    MatInputModule, MatListModule, MatIconModule, MatToolbarModule,
    MatProgressSpinnerModule, MatButtonModule, MatSnackBarModule,
    MatDialogModule, MatTableModule, MatExpansionModule, MatButtonToggleModule,
    MatSortModule, MatSidenavModule, MatCardModule, MatSlideToggleModule,
    BrowserAnimationsModule, MatMomentDateModule, MatDatepickerModule,
    HttpClientModule, MatSliderModule, MatCheckboxModule, MatSelectModule,
    MatPaginatorModule, MatProgressBarModule,
    MatMenuModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
    ToastrModule.forRoot({
      progressBar: true,
      timeOut: 4000,
      maxOpened: 3,
      autoDismiss: true
    }),
    FontAwesomeModule, MatRadioModule, MatAutocompleteModule, MatRippleModule
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: CustomReuseStrategy},
    {provide: LOCALE_ID, useValue: 'nl'},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    PotService, ReservationService, PlugStockService, PwaService, LocationService,
    BeepService, LabelPrintService, PotProductionJobService, UnitsizeService, PlantService, OpenGroundProductionJobService,
    ProductionJobResolverService, ProductionJobService, StockService, DashboardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
