import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {PotService} from '../pot.service';
import {WithScrollContainer} from '../../../_helpers/with-scroll-container';
import {LocationWithVoorraad} from '../location-with-voorraad';
import {PotStock} from '../pot';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Utils} from '../../../_helpers/Utils';


@Component({
  selector: 'app-pot-count',
  templateUrl: './pot-controlled-count.component.html',
  styleUrls: ['./pot-controlled-count.component.scss']
})
export class PotControlledCountComponent extends WithScrollContainer() implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  @ViewChild('bedInput', {static: true}) bedInput: ElementRef;

  searchTerm: string;
  searchTermSubject = new BehaviorSubject<string>('');
  allBeds: LocationWithVoorraad[] = [];
  visibleBeds: LocationWithVoorraad[] = [];
  filteredAndSortedBeds: LocationWithVoorraad[] = [];
  displayedColumns = ['naam', 'rest', 'vrij', 'vr', 'potmaat'];
  fetchingBedsSubject = new BehaviorSubject<boolean>(false);
  fetchingBeds$: Observable<boolean> = this.fetchingBedsSubject.asObservable();
  firstVisibleBedIndex: number;
  noBedsFoundMessage: string;
  subscriptions = new Subscription();
  higherThanPriority: number;
  idToBeFetched: number;
  previousDates: Map<number, Date> = new Map<number, Date>();

  private readonly AMOUNT_BEDS_VISIBLE = 10;
  protected readonly Utils = Utils;

  constructor(
    private potService: PotService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.setupSearchFieldBehaviour();
    this.fetchAllBeds().then(() => {
      this.setBedsVisible();
    });
  }

  private setupSearchFieldBehaviour() {
    this.searchTermSubject.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged(),
    ).subscribe(_ => this.sortBedsOnLocationWithSlider());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async fetchAllBeds() {
    this.fetchingBedsSubject.next(true);
    try {
      let beds: any[];
      beds = await this.potService.getAllStockToBeCounted().toPromise();
      this.allBeds = beds.filter(val => val !== null)
        .map(bed => new LocationWithVoorraad(bed.location, bed.stocks, bed.priority));
    } catch (error) {
      console.error('Unable to get all beds: ' + error);
    }
    this.sortBedsOnLocationWithSlider();
    this.fetchingBedsSubject.next(false);
  }

  syncVisibleBedsWithAllBeds() {
    this.visibleBeds = this.filteredAndSortedBeds.slice(this.firstVisibleBedIndex, this.firstVisibleBedIndex + this.AMOUNT_BEDS_VISIBLE);
  }

  loadMoreItemsBottom() {
    const bedsToAdd: LocationWithVoorraad[] = this.filteredAndSortedBeds.slice(this.firstVisibleBedIndex + this.AMOUNT_BEDS_VISIBLE,
      this.firstVisibleBedIndex + this.AMOUNT_BEDS_VISIBLE + 5);
    bedsToAdd.forEach(bed => this.visibleBeds.push(bed));
    this.visibleBeds.splice(0, bedsToAdd.length);
    this.firstVisibleBedIndex += bedsToAdd.length;
  }

  loadMoreItemsTop() {
    const bedsToAdd: LocationWithVoorraad[] = this.filteredAndSortedBeds
      .slice(this.firstVisibleBedIndex - 6, this.firstVisibleBedIndex - 1);
    bedsToAdd.reverse().forEach(bed => this.visibleBeds.unshift(bed));
    this.firstVisibleBedIndex -= bedsToAdd.length;
    this.visibleBeds.splice(-(bedsToAdd.length), bedsToAdd.length);
  }

  public async onAttach() {
    await super.onAttach();
    if (this.allBeds.length <= 0) {
      this.fetchAllBeds().then(() => {
        this.syncVisibleBedsWithAllBeds();
      });
    } else {
      await this.fetchStockById(this.idToBeFetched);
      this.syncVisibleBedsWithAllBeds();
    }
    if (this.scrollPosition) {
      this.scrollContainer.nativeElement.scrollTo(0, this.scrollPosition);
    } else if (!this.searchTerm) {
      this.bedInput.nativeElement.focus();
    }
    this.fetchingBedsSubject.next(false);
  }

  private setBedsVisible() {
    this.visibleBeds = [];
    this.firstVisibleBedIndex = 0;
    this.syncVisibleBedsWithAllBeds();
  }

  public sortBedsOnLocationWithSlider() {
    if (this.searchTerm) {
      this.filteredAndSortedBeds = this.allBeds.filter(val => this.isPresentOnLocation(val.location));
      if (this.filteredAndSortedBeds.length > 0) {
        this.noBedsFoundMessage = undefined;
        this.firstVisibleBedIndex = 0;
      } else {
        this.noBedsFoundMessage = 'Geen bedden gevonden voor zoekterm: ' + this.searchTerm;
      }
    } else {
      this.filteredAndSortedBeds = this.allBeds;
    }
    if (this.higherThanPriority === undefined) {
      this.higherThanPriority = 0;
    }
    const lastIndex = this.filteredAndSortedBeds.findIndex(val => this.higherThanPriority > val.priority);
    if (lastIndex !== -1) {
      this.filteredAndSortedBeds = this.filteredAndSortedBeds.slice(0, lastIndex);
    }
    this.filteredAndSortedBeds = this.filteredAndSortedBeds.filter(val => val.location !== null)
      .sort((a, b) => a.location.localeCompare(b.location))
      .map(bed => new LocationWithVoorraad(bed.location, bed.stocks, bed.priority));
    this.syncVisibleBedsWithAllBeds();
  }

  async fetchStockById(sleutel: number) {
    try {
      const updatedStock = await this.potService.getStockById(sleutel).toPromise();
      this.findAndReplaceUpdatedStock(updatedStock);
    } catch (error) {
      console.error('Unable to get voorraad for key ' + sleutel + '\n' + error);
    }
  }

  navigateToStock(voorraad: PotStock) {
    this.idToBeFetched = voorraad.id;
    this.router.navigate([`/voorraad/potten/${voorraad.id}`]);
  }

  isPresentOnLocation(bed: string): boolean {
    if (bed && this.searchTerm) {
      return bed.toLowerCase().startsWith(this.searchTerm.toLowerCase());
    }
    return true;
  }

  calculateAmountOfRows(): number {
    let amount = 0;
    this.filteredAndSortedBeds.forEach(val => {
      amount += val.stocks.length;
    });
    return amount;
  }

  resetSearchField() {
    this.searchTerm = '';
    this.searchTermSubject.next(this.searchTerm);
  }

  async setLastInspectionForLocation(event: MouseEvent, bed: LocationWithVoorraad) {
    event.stopPropagation();
    const updatePromises = bed.stocks.map((stock: PotStock) => this.setLastInspection(event, stock));
    await Promise.all(updatePromises);
  }

  async setLastInspection(event: MouseEvent, stock: PotStock) {
    event.stopPropagation();
    if (this.previousDates.has(stock.id) &&
      (new Date(this.previousDates.get(stock.id)) < new Date() || this.previousDates.get(stock.id) === undefined)) {
      stock.lastInspection = this.previousDates.get(stock.id);
      this.previousDates.delete(stock.id);
    } else {
      this.previousDates.set(stock.id, stock.lastInspection);
      stock.lastInspection = new Date();
    }

    return this.potService.updateStock(stock)
      .subscribe((updatedStock: PotStock) => {
        this.findAndReplaceUpdatedStock(updatedStock);
      });
  }

  findAndReplaceUpdatedStock(stock: PotStock) {
    this.filteredAndSortedBeds.forEach(value => {
      const correctStock = value.stocks
        .find(val => val.id === stock.id);
      if (correctStock) {
        value.stocks[value.stocks.indexOf(correctStock)] = stock;
      }
    });
    this.syncVisibleBedsWithAllBeds();
  }
}
