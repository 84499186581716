import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from '../common/base.service';
import {Stock} from './stock';

@Injectable({
  providedIn: 'root'
})
export class StockService extends BaseService {

  private apiUrl = this.baseUrl + '/stock';

  updateStock(stock: Stock): Observable<Stock> {
    return this.http.put<Stock>(this.apiUrl + '/' + stock.id, stock);
  }

}
