<div class="loading-shade" *ngIf="busyFetching | async">
  <mat-spinner></mat-spinner>
</div>

<div class="fetch-data-bar">
  <mat-form-field class="fill-space">
    <input matInput placeholder="Filteren" type="text" [(ngModel)]="sortKeyword"
           (keyup)="searchTermSubject.next(sortKeyword)" (keyup.enter)="searchTermSubject.next(sortKeyword)">
  </mat-form-field>
  <app-location-selector></app-location-selector>
  <button mat-fab aria-label="Button to fetch data using weeks" color="primary"
          (click)="refreshData()">
    <mat-icon>refresh</mat-icon>
  </button>
</div>

<div id="results" *ngIf="pottingProductionJobs && pottingProductionJobs.length > 0">
  <div id="results-header">
    <div otherCol>Hoeveelheid</div>
    <div otherCol>Locatie</div>
    <div otherCol>Productie datum</div>
    <div otherCol>leverancier</div>
    <div otherCol>Maat</div>
    <div otherCol>Opmerking</div>
    <div otherCol>Afronden</div>
  </div>
  <div #scrollContainer id="search-result-beds">
    <mat-card class="production-job" *ngFor="let pottingProductionJob of pottingProductionJobsToShow">
      <mat-card-title class="description"> {{pottingProductionJob.plantName}} - {{pottingProductionJob.plantCode}}
      </mat-card-title>
      <mat-card-content class="card-content">
        <div otherCol>{{pottingProductionJob.orderedAmount}}</div>
        <div otherCol>{{pottingProductionJob.productionLocation | productionLocation}}</div>
        <div otherCol>{{pottingProductionJob.deliveryDate}}</div>
        <div otherCol>{{pottingProductionJob.supplier}}</div>
        <div otherCol>{{pottingProductionJob.targetUnitSizeName}}</div>
        <div otherCol>{{pottingProductionJob.remarks}}</div>
        <div otherCol>
                    <button mat-icon-button aria-label="Button to open dialog to create stock"
                            (click)="openUpdateStockDialog(pottingProductionJob)">
                      <mat-icon>assignment_turned_in</mat-icon>
                    </button>
        </div>
      </mat-card-content>
    </mat-card>
    <button mat-raised-button id="more-jobs-button" class="more-stocks-button"
            [disabled]="firstVisiblePottingProductionJobsIndex >= filteredPottingProductionJobs.length - 50" (click)="loadMoreItemsBottom()">
      <mat-icon aria-label="Load more">expand_more</mat-icon>
      Meer voorraden laden
    </button>
  </div>
</div>
<div class="no-jobs-found-message" *ngIf="noProductionJobsFoundMessage">{{noProductionJobsFoundMessage}}</div>
