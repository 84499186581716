import {Injectable} from '@angular/core';
import {BaseService} from '../common/base.service';
import {Observable} from 'rxjs';
import EntityNameAndId from './EntityNameAndId';
import {SalesOrderItem} from '../checklist/sales-order-item';

@Injectable({
  providedIn: 'root'
})
export class BarcodeService extends BaseService {

  private url = this.baseUrl + '/barcode';

  getEntityNameAndIdByBarcode(barcode: number): Observable<EntityNameAndId[]> {
    return this.http.get<EntityNameAndId[]>(`${this.url}/${barcode}`);
  }

  getSalesOrderByBarcode(barcode: number): Observable<number> {
    return this.http.get<number>(`${this.url}/find-sales-order/${barcode}`);
  }

  getSalesOrderItemByBarcode(barcode: number): Observable<SalesOrderItem> {
    return this.http.get<SalesOrderItem>(`${this.url}/find-sales-order-item/${barcode}`);
  }
}
